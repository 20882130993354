@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';

:root {
    --cart-overlay-border-color: #{$blue30};
    --cart-overlay-border-radius: 0;
    --cart-overlay-promo-border-radius: 0;
    --cart-overlay-promo-background: #{$blue30};
    --cart-overlay-box-shadow-color: rgba(0, 0, 0, 0.15);
}

[dir='ltr'] .CartOverlay {
    &-CartButton {
        &,
        &:hover,
        &:focus {
            border-radius: 0;
        }
    }

    &-Total {
        span {
            font-weight: bold;
            font-size: 20px;
            color: $black;
        }

        p {
            font-weight: 400;
            font-size: 15px;
            color: $grey-dark;
            margin: 7px 0 0;

            &::first-letter {
                text-transform: uppercase;
            }
        }
    }
}
